export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },

    {
        id: 3,
        label: 'menuitems.masterData.text',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 30,
                label: 'menuitems.masterData.list.brands',
                link: '/master-data/brands'
            },
            {
                id: 4,
                label: 'menuitems.masterData.list.menus',
                link: '/master-data/menus'
            },
            {
                id: 5,
                label: 'menuitems.masterData.list.subMenus',
                link: '/master-data/sub-menus'
            },
            {
                id: 6,
                label: 'menuitems.masterData.list.listSubMenus',
                link: '/master-data/list-sub-menu'
            },

            {
                id: 7,
                label: 'menuitems.masterData.list.countries',
                link: '/master-data/countries'
            },
            {
                id: 8,
                label: 'menuitems.masterData.list.colors',
                link: '/master-data/colors'
            },
            {
                id: 9,
                label: 'menuitems.masterData.list.sizes',
                link: '/master-data/sizes'
            },
            {
                id: 29,
                label: 'menuitems.masterData.list.specifications',
                link: '/master-data/specifications'
            },
            {
                id: 30,
                label: 'menuitems.masterData.list.filters',
                link: '/master-data/filters'
            },
            {
                id: 31,
                label: 'menuitems.masterData.list.productFilterSpecification',
                link: '/master-data/product-specification-filter'
            },
            {
                id: 32,
                label: 'menuitems.masterData.list.homeSliders',
                link: '/master-data/home-sliders'
            },
            {
                id: 33,
                label: 'menuitems.masterData.list.shopByRooms',
                link: '/master-data/shop-by-rooms'
            },
        ]
    },
    {
        id: 34,
        label: 'menuitems.cms.text',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 35,
                label: 'menuitems.cms.list.termAndCondition',
                link: '/termAndCondition'
            },
            {
                id: 36,
                label: 'menuitems.cms.list.privacyPolicy',
                link: '/privacyPolicy'
            },
            {
                id: 37,
                label: 'menuitems.cms.list.cookiesPolicy',
                link: '/cookiesPolicy'
            }
        ]
    },
    {
        id: 10,
        label: 'menuitems.products.text',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 11,
                label: 'menuitems.products.list.productManage',
                link: '/products'
            },
            {
                id: 38,
                label: 'menuitems.products.list.collections',
                link: '/collections'
            },
            {
                id: 39,
                label: 'menuitems.products.list.collectionProduct',
                link: '/collection-products'
            }
        ]
    },
    {
        id: 12,
        label: 'menuitems.b2bUsers.text',
        icon: 'ri-account-circle-line',
        subItems: [
            {
                id: 13,
                label: 'menuitems.b2bUsers.list.b2bUserManage',
                link: '/users'
            }
        ]
    },
    // {
    //     id: 40,
    //     label: 'menuitems.b2bSectionPage.text',
    //     icon: 'ri-account-circle-line',
    //     subItems: [
    //         {
    //             id: 41,
    //             label: 'menuitems.b2bSectionPage.list.b2bSection',
    //             link: '/sections'
    //         },
    //         {
    //             id: 42,
    //             label: 'menuitems.b2bSectionPage.list.b2bPage',
    //             link: '/pages'
    //         }
    //     ]
    // },
    // {
    //     id: 14,
    //     label: 'menuitems.adminUsers.text',
    //     icon: 'ri-account-circle-line',
    //     subItems: [
    //         {
    //             id: 43,
    //             label: 'menuitems.adminUsers.list.section',
    //             link: '/admin-sections'
    //         },
    //         {
    //             id: 44,
    //             label: 'menuitems.adminUsers.list.sectionPages',
    //             link: '/admin-page'
    //         },
    //         {
    //             id: 15,
    //             label: 'menuitems.adminUsers.list.roleManagement',
    //             link: '/roles'
    //         },
    //         {
    //             id: 16,
    //             label: 'menuitems.adminUsers.list.userManagement',
    //             link: '/user-management'
    //         }
    //     ]
    // },
    {
        id: 17,
        label: 'menuitems.orders.text',
        icon: 'ri-account-circle-line',

        subItems: [

            {
                id: 18,
                label: 'menuitems.orders.list.openOrders',
                link: '/open-order/order-list'
            },
            {
                id: 21,
                label: 'menuitems.orders.list.cancelledOrders',
                link: '/cancel-order/cancel-order-list'
            },
            {
                id: 46,
                label: 'menuitems.orders.list.payment.text',
                link: '/user-payments',
                badge: {
                    variant: "danger",
                },
            },
            // {
            //     id: 19,
            //     label: 'menuitems.orders.list.inTransitOrders',
            //     link: '/404'
            // },
            // {
            //     id: 20,
            //     label: 'menuitems.orders.list.deliveredOrders',
            //     link: '/404'
            // },

        ]
    },
    // {
    //     id: 22,
    //     label: 'menuitems.coupons.text',
    //     icon: 'ri-store-2-line',
    //     subItems: [
    //         {
    //             id: 23,
    //             label: 'menuitems.coupons.list.couponManage',
    //             link: '/coupons'
    //         }
    //     ]
    // },
    // {
    //     id: 24,
    //     label: 'menuitems.settings.text',
    //     icon: 'ri-store-2-line',
    //     subItems: [
    //         {
    //             id: 25,
    //             label: 'menuitems.settings.list.smsGatewaySetting',
    //             link: '/404'
    //         },
    //         {
    //             id: 26,
    //             label: 'menuitems.settings.list.alertEmailSetting',
    //             link: '/404'
    //         }
    //     ]
    // },
    {
        id: 27,
        label: 'menuitems.changePassword.text',
        icon: 'ri-calendar-2-line',
        link: '/change-password'
    },
    {
        id: 45,
        label: 'menuitems.activity-logs.text',
        icon: 'ri-calendar-2-line',
        link: '/activity-logs'
    },

    {
        id: 28,
        label: 'menuitems.logout.text',
        icon: 'ri-calendar-2-line',
        link: '/logout'
    },
]